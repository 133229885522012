.react-player__preview {
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

video::-webkit-media-controls-play-button,
video::-webkit-media-controls-mute-button,
video::-webkit-media-controls-fullscreen-button,
video::-webkit-media-controls-seek-back-button,
video::-webkit-media-controls-seek-forward-button,
video::-webkit-media-controls-rewind-button,
video::-webkit-media-controls-overflow-button {
    transform: scale(1.75);
}
audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-mute-button,
audio::-webkit-media-controls-seek-back-button,
audio::-webkit-media-controls-seek-forward-button,
audio::-webkit-media-controls-rewind-button,
audio::-webkit-media-controls-overflow-button {
    transform: scale(1.75);
}
