@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 300;
    src: url("../public/fonts/roboto-v32-latin-300.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    src: url("../public/fonts/roboto-v32-latin-regular.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    src: url("../public/fonts/roboto-v32-latin-500.woff2") format("woff2");
}

@font-face {
    font-display: swap;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    src: url("../public/fonts/roboto-v32-latin-700.woff2") format("woff2");
}
